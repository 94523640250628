<template>
  <!--新增授信账户 -->
  <app-dialog2 ref="dialog" title="提示" :width="560">
    <app-status ref="page" class="appDetail">
      <div class="content">
        <img v-if="type == 'error'" class="status" src="@/assets/image/status/error.png" alt="">
        <img v-if="type == 'success'" class="status" src="@/assets/image/status/success.png" alt="">
        <img v-if="type == 'warning'" class="status" src="@/assets/image/status/warning.png" alt="">
        {{ content }}
      </div>
      <div class="appDetail-footer" style="margin-top: 20px">
        <div class="appDetail-buttongourp">
          <el-button @click="closeFun()" type="primary" class="appDetail-buttongourp-button"> 关闭({{ num }})
          </el-button>
        </div>
      </div>
    </app-status>
  </app-dialog2>
</template>

<script>
export default {
  emits: ['confirm'],
  components: {},
  props: {},
  data() {
    return {
      type: '',
      content: '',
      num: 3,
      intervalId: '',
    };
  },
  methods: {
    /**
     * 打开弹窗
     */
    async openTip(type, content, time = 3) {
      await this.$refs.dialog.open();
      this.type = type;
      this.content = content;
      this.num = time
      this.startCountdown();
    },
    startCountdown() {
      this.intervalId = setInterval(() => {
        if (this.num <= 0) {
          this.closeFun()
        } else {
          this.num--;
        }
      }, 1000);
    },
    closeFun() {
      clearInterval(this.intervalId);
      this.$emit('tipClose')
      this.$refs.dialog.close();

    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  gap: 8px;
}

.status {
  width: 20px;
  height: 20px;
}

.title {
  font-size: 28px;
  font-weight: bold;
}
</style>
